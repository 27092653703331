import React, { useMemo, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as auth from '../../../app/modules/Auth/_redux/authRedux';
import objectPath from 'object-path';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
// LayoutContext
import { useHtmlClassService } from '../_core/MetronicLayout';
// Import Layout components
import { Header } from './header/Header';
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { Aside } from './aside/Aside';
import { Footer } from './footer/Footer';
import { LayoutInit } from './LayoutInit';
import { SubHeader } from './subheader/SubHeader';
import { QuickPanel } from './extras/offcanvas/QuickPanel';
import QuickUser from './extras/offcanvas/QuickUser';
import { ScrollTop } from './extras/ScrollTop';
import { StickyToolbar } from './extras/StickyToolbar';
import bgImage from '../../../assets/background-15.png';
import svg1 from '../../../assets/Rectangle 44.svg';
import svg2 from '../../../assets/Rectangle 45.svg';
import bgMobile from '../../../assets/FundcrowdMobileBg.png';
import GreenRactangle from '../../../assets/GreenRactangle.png';
import useWindowSize from '../../../app/pages/dashboard/chart/WindowSize';
import { BACKEND_URL, FRONTEND_URL } from '../../../config';
import axios from 'axios';
import { useRouteMatch } from 'react-router-dom';
import { useShouldSelectBranch } from '../../../app/hooks/useShouldSelectBranch';

export function Layout({ children }) {
  const dispatch = useDispatch();
  const uiService = useHtmlClassService();
  const location = useLocation();
  const isHomePage = location.pathname.includes('/home');
  const isRewardPage = location.pathname.includes('/reward');
  const isShoppingPage = location.pathname.includes('/shopping');
  const isShopRewardPage = location.pathname.includes('/shop-reward');
  const isCheckoutPage = location.pathname.includes('/checkout');
  const isAddressPage = location.pathname.includes('/address');
  const isPaymentPage = location.pathname.includes('/payment');
  const isSlipPage = location.pathname.includes('/up-slip');
  const isOrderPage = location.pathname.includes('/order');
  const isPromotionPage = location.pathname.includes('/promotion/');
  const isShopProductPage = location.pathname.includes('/shopProduct/');
  const isShopProductPage2 =
    location.pathname.includes('/products/') &&
    (location.pathname.includes('/new') || location.pathname.includes('/edit'));
  const isOrderHistoryPage = location.pathname.includes('/order-history');
  const isorderManagementPage = location.pathname.includes('/orderManagement');
  const isInventoryPage = location.pathname.includes('inventory');
  const isShoppingCartSettingPage = location.pathname.includes('/shopping-cart-setting');
  const isLineOASettingPage = location.pathname.includes('/setting-line-oa');
  const isContactPage = location.pathname.includes('/board') || location.pathname.includes('customer-contact');
  const isRegistrationSettingPage = location.pathname.includes('registration-perks');
  const isAdminManagePage = location.pathname.includes('adminManage');
  const isBranchManagePage = location.pathname.includes('branch-management');
  const isAddOnPage = location.pathname.includes('add-on');
  const isSelectPaymentPage = location.pathname.includes('select-payment') && location.pathname.includes('order');
  const isSelectCategoryPage = location.pathname.includes('categories');

  const { user, shopSetting } = useSelector(state => state.auth);

  const isSelectingLoginBranch = useShouldSelectBranch();

  const layoutIsHidden =
    isHomePage ||
    isRewardPage ||
    isShoppingPage ||
    isShopRewardPage ||
    isCheckoutPage ||
    isAddressPage ||
    isPaymentPage ||
    isSlipPage ||
    isOrderPage ||
    isPromotionPage ||
    isShopProductPage ||
    isShopProductPage2 ||
    isInventoryPage ||
    isShoppingCartSettingPage ||
    isContactPage ||
    isLineOASettingPage ||
    isRegistrationSettingPage ||
    isAdminManagePage ||
    isBranchManagePage ||
    isContactPage ||
    isSelectingLoginBranch ||
    isAddOnPage ||
    isSelectPaymentPage ||
    isSelectCategoryPage;

  const isBookingDetailPage = useRouteMatch('/shop/:shopId/booking/:bookingId/detail');
  const isMGMPage = useRouteMatch('/shop/:shopId/mgm/detail');

  const hideAsideNotLogin = !user && (isBookingDetailPage || isMGMPage);
  const hideAside = hideAsideNotLogin || isSelectingLoginBranch;

  const padding =
    isorderManagementPage || isOrderHistoryPage
      ? {
          padding: '5%'
        }
      : {};

  const size = useWindowSize();

  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      desktopHeaderDisplay: objectPath.get(uiService.config, 'header.self.fixed.desktop'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended')
    };
  }, [uiService]);

  const previewTheme = JSON.parse(window.localStorage.getItem('THEME_PREVIEW'));
  const isPreviewTheme = previewTheme?.background ? true : false;
  const previewImage = JSON.parse(window.localStorage.getItem('IMG_PREVIEW'));
  const isPreviewImagee = previewImage?.img ? true : false;
  const isPreview = isPreviewImagee || isPreviewTheme ? true : false;

  const paddingTop = {
    paddingTop: layoutIsHidden ? 0 : undefined
  };

  const paddingLeft = hideAside
    ? {
        paddingLeft: 0
      }
    : {};

  const imageStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'noRepeat'
  };

  const initStyle = {
    // ...imageStyle,
    // backgroundImage: `url(${bgImage})`+
    background: '#5e73b7'
  };

  const [background, setBackground] = useState(initStyle);
  const [showSVG, setShowSVG] = useState(true);

  useEffect(() => {
    const backgroundImage = shopSetting?.backgroundImage;
    const backgroundColor = shopSetting?.backgroundColor?.background;
    const isShopTheme = backgroundColor ? true : false;

    if (isPreview) {
      setShowSVG(false);
      if (previewImage?.img && !isPreviewTheme) {
        const image = new Image();
        image.src = URL.createObjectURL(base64ToImage(previewImage?.img));
        setBackground({
          backgroundImage: `url(${image.src})`,
          ...imageStyle
        });
      } else if (isPreviewTheme && !previewImage?.img) {
        setBackground({
          background: previewTheme?.background
        });
      }
    } else if (!isPreview && (backgroundImage || backgroundColor)) {
      setShowSVG(false);
      if (backgroundImage && backgroundImage.length > 0 && !isShopTheme) {
        setBackground({
          backgroundImage: `url(${backgroundImage[0]})`,
          ...imageStyle
        });
      } else if (isShopTheme) {
        setBackground({
          background: backgroundColor
        });
      }
    } else {
      setShowSVG(true);
      setBackground(initStyle);
    }
  }, [shopSetting]);

  function base64ToImage(base64String) {
    const base64 = base64String.split('data:image/png;base64,')[1];
    const binary = atob(base64);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/png' });
  }

  const wrapperStyle = { ...background, ...paddingTop, ...paddingLeft };

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && !hideAside && <Aside />}
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper" style={wrapperStyle}>
            <Header />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {/* {layoutProps.subheaderDisplay && <SubHeader/>} */}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid flex-column">
                  {/*begin::Container*/}
                  {/* <div className={layoutProps.contentContainerClasses} style={{ zIndex: 2 }}> */}
                  <div
                    className={layoutIsHidden ? '' : layoutProps.contentContainerClasses}
                    style={{ zIndex: 2, ...padding }}
                  >
                    {children}
                  </div>
                  {/*end::Container*/}
                  {!isHomePage && showSVG ? (
                    <>
                      <img
                        src={svg1}
                        style={{ position: 'fixed', bottom: '-60px', width: size.width < 550 ? 'auto' : '100%' }}
                      />
                      <img
                        src={svg2}
                        style={{ position: 'fixed', bottom: 0, width: size.width < 550 ? 'auto' : '100%' }}
                      />
                    </>
                  ) : null}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser />
      {/* <QuickPanel /> */}
      <ScrollTop />
      {/* <StickyToolbar /> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
