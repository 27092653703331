/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, lazy } from 'react';
import liff from '@line/liff';
import { Link, Switch, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ContentRoute, LayoutSplashScreen } from '../../../../_metronic/layout';

import '../../../../_metronic/_assets/sass/pages/users/login-1.scss';
import { lineLogin } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';

import WechatJSSDK from 'wechat-jssdk';
import axios from 'axios';
import WechatLogin from './socialLogin/WechatLogin';
import bgImage from '../../../../assets/background-15.png';
import { getTokenBySeqId, setTokenBySeqId } from '../../../utils/localstorage';
import { BACKEND_URL, FRONTEND_URL } from '../../../../config';
import FeaturePackageEdit from './management/featurePackage/FeaturePackageEdit';
import { useIntl } from 'react-intl';

const Login = lazy(() => import('./Login'));
const Management = lazy(() => import('./management'));
const SocialLogin = lazy(() => import('./socialLogin'));
const CreateBooking = lazy(() => import('../../ECommerce/pages/booking/management/CreateBooking'));
const AnnouncementsPage = lazy(() => import('./management/announcement/AnnouncementsPage'));
const AnnouncementEdit = lazy(() => import('./management/announcement/announcement-edit-dialog/AnnouncementEdit'));
const BookingDetail = lazy(() => import('../../ECommerce/pages/booking/BookingDetail'));
const ManagementLogin = lazy(() => import('./ManagementLogin'));
const Registration = lazy(() => import('./Registration'));
const Booking2CreateBookingSelectBranchPage = lazy(() =>
  import('../../ECommerce/pages/bookingV2/createBooking/Booking2CreateBookingSelectBranchPage')
);
const Booking2BookingWorkingDaysPage = lazy(() =>
  import('../../ECommerce/pages/bookingV2/createBooking/Booking2BookingWorkingDaysPage')
);
const Booking2BookingConditionPage = lazy(() =>
  import('../../ECommerce/pages/bookingV2/createBooking/Booking2BookingConditionPage')
);
const Booking2CreateBookingPage = lazy(() =>
  import('../../ECommerce/pages/bookingV2/createBooking/Booking2CreateBookingPage')
);
const FeaturePackageEditPage = lazy(() => import('./management/featurePackage/FeaturePackageEdit'));

const Booking2DetailPage = lazy(() => import('../../ECommerce/pages/bookingV2/bookingDetail/Booking2DetailPage'));

const MGM_Detail = lazy(() => import('../../ECommerce/pages/MGM/MGM-Detail/Detail'));

const LiffLogin = lazy(() => import('../../ECommerce/pages/MGM/MGM-Detail/LiffLogin'));

export default function AuthPage() {
  const [loading, setLoading] = useState(true);
  const [isWeChat, setIsWeChat] = useState(false);
  const [wechatObj, setWechatObj] = useState({});
  const [wxJSSDKerr, setWxJSSDKerr] = useState({});

  const history = useHistory();
  const location = useLocation();
  // const search = decodeURIComponent(location.search);
  const search = location.search;
  const queryString = new URLSearchParams(search);
  const isLoginPage = location.pathname.includes('login');
  const isRegistrationPage = location.pathname.includes('registration');
  const isHideSidePane = location.pathname.includes('booking') || location.pathname.includes('mgm');
  const shopId = queryString.get('shop');
  const isLiff = queryString.get('liff');
  const inviteCode = queryString.get('invitecode');
  const redeem = queryString.get('redeem');
  const isLineSuccess = queryString.get('line');
  const lineProfile = queryString.get('profile');
  const salesName = queryString.get('sales');
  const mgm = queryString.get('mgm') || window.localStorage.getItem('mgm');
  const referral = queryString.get('referral') || window.localStorage.getItem('referral');
  const branchFriendlyId = queryString.get('branch');

  const [shopDetail, setShopDetail] = useState(null);
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [size, setSize] = useState({ width: windowSize.innerWidth, height: windowSize.innerHeight });
  const intl = useIntl();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // const matches = useMediaQuery('(max-width:991px)');
  useEffect(() => {
    setSize({ width: windowSize.innerWidth, height: windowSize.innerHeight });
  }, [windowSize]);

  const isWithinWeChat = () => {
    const ua = navigator.userAgent.toLowerCase();
    // is within wechat
    // micromessenger is the keyword that indicates it's within wechat
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      // setIsWeChat(true);
      return true;
    }
    return false;
  };

  const requestForWeChatParams = async () => {
    // Make sure it's the url that you have whitelisted in WeChat Official Account Dashboard
    const url = window.location.href.split('#')[0];
    // If you want to test on your device, be sure to use your IP address instead of localhost
    const YourBackEndUrl = `${BACKEND_URL}/wechat/get-signature?url=${encodeURIComponent(url)}`;

    try {
      const { data } = await axios.get(YourBackEndUrl);

      const config = {
        //below are mandatory options to finish the wechat signature verification
        //the 4 options below should be received like api '/get-signature' above
        appId: data.appId,
        nonceStr: data.nonceStr,
        signature: data.signature,
        timestamp: data.timestamp,
        //below are optional
        success: jssdkInstance => {
          console.log('success', jssdkInstance);
        },
        error: (err, jssdkInstance) => {
          console.log('failed', jssdkInstance);
        },
        //enable debug mode, same as debug
        debug: true,
        // Tell WeChat what functionalities you would like to use
        jsApiList: [
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'onMenuShareQQ',
          'onMenuShareWeibo',
          'onMenuShareQZone',
          'startRecord',
          'stopRecord',
          'onVoiceRecordEnd',
          'playVoice',
          'pauseVoice',
          'stopVoice',
          'onVoicePlayEnd',
          'uploadVoice',
          'downloadVoice',
          'chooseImage',
          'previewImage',
          'uploadImage',
          'downloadImage',
          'translateVoice',
          'getNetworkType',
          'openLocation',
          'getLocation',
          'hideOptionMenu',
          'showOptionMenu',
          'hideMenuItems',
          'showMenuItems',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem',
          'closeWindow',
          'scanQRCode',
          'chooseWXPay',
          'openProductSpecificView',
          'addCard',
          'chooseCard',
          'openCard'
        ], //optional, pass all the jsapi you want, the default will be ['onMenuShareTimeline', 'onMenuShareAppMessage']
        customUrl: '' //set custom weixin js script url, usually you don't need to add this js manually
      };
      // const wechatObj = new WechatJSSDK(config);

      // console.log({ wechatObj });
      // await wechatObj.initialize();
      console.log('After initialize');
      // Usually you want to create a wrapper component or set it as a window variable to use wechatObj everywhere else without reinitializing it.
      // setWechatObj(wechatObj);
    } catch (error) {
      console.log({ error });
      // setWxJSSDKerr(error);
    }
  };

  const initLiff = async () => {
    let profile = null;
    try {
      await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
      if (liff.isLoggedIn()) {
        profile = await liff.getProfile();
        const res = await lineLogin(profile.userId, shopId);
        dispatch({ type: auth.actionTypes.Login, payload: { authToken: res.data.token } });
        if (redeem) {
          history.push({ pathname: `/shop/${shopId}/redeem/${redeem}` });
        }
      } else {
        let redirectUri = `${FRONTEND_URL}?shop=${shopId}&liff=true`;
        redirectUri += inviteCode ? `&invitecode=${inviteCode}` : '';
        redirectUri += salesName ? `&sales=${salesName}` : '';
        redirectUri += referral ? `&referral=${referral}` : '';
        redirectUri += mgm ? `&mgm=${mgm}` : '';
        liff.login({ redirectUri });
      }
    } catch (err) {
      if (err.message.includes('404')) {
        let searchStr = '';
        searchStr += inviteCode ? `&invitecode=${inviteCode}` : '';
        searchStr += salesName ? `&sales=${salesName}` : '';
        searchStr += referral ? `&referral=${referral}` : '';
        searchStr += mgm ? `&mgm=${mgm}` : '';
        history.push({
          pathname: `/shop/${shopId}/registration`,
          search: searchStr,
          profile,
          error: err.message
        });
        setLoading(false);
      }
      console.dir(err);
    }
  };

  const navigateToLoginPage = () => history.push(`/shop/${shopId}/login`);

  const initLine = async () => {
    let profile = null;
    try {
      profile = JSON.parse(lineProfile);
      const res = await lineLogin(
        profile.userId,
        shopId,
        profile.pictureUrl,
        profile.email,
        profile.alternativeLineId,
        branchFriendlyId
      );
      dispatch({ type: auth.actionTypes.Login, payload: { authToken: res.data.token } });
      dispatch({ type: auth.actionTypes.UserLoaded, payload: { authToken: res.data.user } });
      setTokenBySeqId(shopId, res.data.token);
      if (redeem) {
        history.push({ pathname: `/shop/${shopId}/redeem/${redeem}` });
      }
    } catch (err) {
      let code = localStorage.getItem('_ivc');
      // let sales = localStorage.getItem('_sales');
      let redeem = localStorage.getItem('_redeem');
      localStorage.removeItem('_ivc');
      // localStorage.removeItem('_sales');
      localStorage.removeItem('_redeem');

      let searchStr = '';
      searchStr += code ? `&invitecode=${code}` : '';
      searchStr += salesName ? `&sales=${salesName}` : '';
      searchStr += redeem ? `&redeem=${redeem}` : '';
      searchStr += referral ? `&referral=${referral}` : '';
      searchStr += mgm ? `&mgm=${mgm}` : '';
      searchStr += branchFriendlyId ? `&branch=${branchFriendlyId}` : '';

      setLoading(false);

      if (err.message.includes('404')) {
        history.push({
          pathname: `/shop/${shopId}/registration`,
          search: searchStr,
          profile,
          error: err.message
        });
        return;
      } else if (err.response.data.message === 'User has been suspended') {
        window.alert('User has been suspended, please contact admin');
      } else if (err.response.data.message === 'Branch is not active') {
        window.alert('Branch is not active, please contact admin');
      } else if (err.response.data.message === 'Branches not activated') {
        window.alert('Branches not activated, please contact admin');
      } else if (err.response.data.message === 'User has been Expired') {
        window.alert('User has been Expired, please contact admin');
      } else {
        window.alert(intl.formatMessage({ id: 'alert.error' }));
      }
      navigateToLoginPage();
      console.dir(err);
    }
  };

  const getShopInfo = async seqId => {
    let { data } = await axios.get(`${BACKEND_URL}/shop/${seqId}`);
    dispatch({ type: auth.actionTypes.GetShopInfo, payload: data });
  };

  useEffect(() => {
    const seqId = window.location.pathname.split('/');
    if (parseInt(seqId[2]) > 0) {
      getLogo(seqId[2]);
      GetPointandWalletSetting(seqId[2]);
      GetShopMenuSetting(seqId[2]);
      GetCustomerMenuSetting(seqId[2]);
      getShopInfo(seqId[2]);
      GetRegistrationField(seqId[2]);
    }

    if (shopId && isLiff) {
      initLiff();
    } else if (shopId && isLineSuccess) {
      console.log('attempting to login with line');
      // return;
      initLine();
    } else {
      setLoading(false);
    }

    const storageToken = getTokenBySeqId(seqId[2]);

    if (storageToken) {
      dispatch({ type: auth.actionTypes.Login, payload: { authToken: storageToken, seqId: seqId[2] } });
    }

    // const isWechat = isWithinWeChat();
    // if (!isWechat) {
    //   requestForWeChatParams();
    // }
    // axios.get(`${process.env.REACT_APP_BACKEND_IP_ADDRESS}/wechat/oauth`)
    //   .then(({ data }) => {
    //     const { url } = data;
    //     history.push(url);
    //   });
  }, []);

  const seqId = window.location.pathname.split('/')[2];

  const getLogo = async id => {
    const { data } = await axios.get(`${BACKEND_URL}/shop/${id}`);
    dispatch({ type: auth.actionTypes.GetShopInfo, payload: data });
    setShopDetail(data.shop);
  };

  const GetShopSetting = async id => {
    let { data } = await axios.get(`${BACKEND_URL}/setting/auth/${id}`);
    dispatch({ type: auth.actionTypes.GetShopSetting, payload: data });
    const backgroundImage = data?.shopSetting?.backgroundImage;
    const backgroundColor = data?.shopSetting?.backgroundColor.background;
    const isShopTheme = backgroundColor ? true : false;

    if (backgroundImage && backgroundImage.length > 0) {
      setBackground({
        backgroundImage: `url(${backgroundImage[0]})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'noRepeat',
        backgroundPosition: 'center'
      });
    } else if (isShopTheme) {
      setBackground({
        background: backgroundColor
      });
    } else {
      if (isRegistrationPage || isLoginPage) {
        setBackground({ background: '#5e73b7' });
      } else {
        setBackground(initStyle);
      }
    }
  };

  const GetPointandWalletSetting = async id => {
    let { data } = await axios.get(`${BACKEND_URL}/pointandwallet/auth/${id}`);
    dispatch({ type: auth.actionTypes.GetPointandWalletSetting, payload: data });
  };
  const GetShopMenuSetting = async id => {
    let { data } = await axios.get(`${BACKEND_URL}/menu/auth/shop/${id}`);
    dispatch({ type: auth.actionTypes.GetShopMenuSetting, payload: data });
  };
  const GetCustomerMenuSetting = async id => {
    let { data } = await axios.get(`${BACKEND_URL}/menu/auth/customer/${id}`);
    dispatch({ type: auth.actionTypes.GetCustomerMenuSetting, payload: data });
  };
  const GetRegistrationField = async id => {
    let { data } = await axios.get(`${BACKEND_URL}/regis-field/auth/${id}`);
    dispatch({ type: auth.actionTypes.GetRegistrationField, payload: data });
  };

  const initStyle = {
    backgroundImage: `url(${bgImage})`
    // background: '#5e73b7'
  };

  const [background, setBackground] = useState(initStyle);

  const loginPageQueryParams = branchFriendlyId ? `branch=${branchFriendlyId}` : '';

  useEffect(() => {
    if (seqId == null || isNaN(+seqId)) return;
    window.localStorage.removeItem('THEME_PREVIEW');
    window.localStorage.removeItem('IMG_PREVIEW');
    GetShopSetting(seqId);
    GetPointandWalletSetting(seqId);
    GetShopMenuSetting(seqId);
    GetCustomerMenuSetting(seqId);
    GetRegistrationField(seqId);
  }, [isRegistrationPage, isLoginPage]);

  // console.log(background);

  if (loading) return <LayoutSplashScreen />;

  // console.log({ ...background });

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className={`login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid ${
            location.pathname.includes('mgm') ? '' : 'bg-white'
          }`}
          id="kt_login"
        >
          {/* <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              background: 'linear-gradient(135deg, #7AB8B6 40%, white 100%)'
            }}
          >
            <div className="d-flex flex-row-fluid flex-column justify-content-between ">
              <Link to="/" className="flex-column-auto mt-5">
                <img alt="Logo" className="max-h-70px" src={toAbsoluteUrl('/media/logos/Funcrowd-01.svg')} />
              </Link>
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
            
              </div>
             
            </div>
          </div> */}

          <div
            className="flex-row-fluid d-flex flex-column position-relative p-7"
            style={isHideSidePane ? { background: 'transparent' } : { ...background }}
          >
            <div className={`d-flex flex-column flex-column-fluid  ${isLoginPage ? 'justify-content-center' : ''}`}>
              {!isHideSidePane && (
                <div className="d-flex flex-column flex-wrap align-items-center flex-center mb-4 mt-lg-0">
                  {shopDetail && shopDetail.logo && (
                    <img
                      alt="Logo"
                      className="img-fluid mx-auto"
                      style={{ maxHeight: isLoginPage ? 200 : 60 }}
                      src={shopDetail.logo.data}
                    />
                  )}
                  {shopDetail && (
                    <h2 style={{ color: 'rgb(122, 184, 182)', margin: '30px 0 20px 0' }}>{shopDetail.name}</h2>
                  )}
                </div>
              )}
              <div className={`d-flex flex-column ${isHideSidePane && '!align-items-center'} flex-start mt-4 mt-lg-0`}>
                <Switch>
                  {/* <ContentRoute exact path="/oauth-callback" render={WechatLogin} /> */}
                  <ContentRoute exact path="/admin/login" component={Login} />
                  <ContentRoute exact path="/shop/management/login" component={ManagementLogin} />
                  <ContentRoute exact path="/shop/management" component={Management} />
                  <ContentRoute exact path="/shop/management/announcement" component={AnnouncementsPage} />
                  <ContentRoute exact path="/shop/management/announcement/new" component={AnnouncementEdit} />
                  <ContentRoute
                    exact
                    path="/shop/management/announcement/:announcementId/edit"
                    component={AnnouncementEdit}
                  />
                  <ContentRoute
                    exact
                    path="/shop/management/announcement/:announcementId/delete"
                    component={AnnouncementsPage}
                  />
                  <ContentRoute exact path="/shop/management/feature-package/new" component={FeaturePackageEditPage} />
                  <ContentRoute
                    path="/shop/management/feature-package/:featurePackageId"
                    component={FeaturePackageEditPage}
                  />
                  <ContentRoute exact path="/shop/:seqId/login" component={SocialLogin} />
                  <ContentRoute exact path="/shop/:seqId/applyInvite" component={SocialLogin} />
                  <ContentRoute exact path="/shop/:seqId/registration" component={Registration} />
                  <ContentRoute exact path="/shop/:seqId/booking" component={CreateBooking} />
                  <ContentRoute exact path="/shop/:seqId/booking/:bookingId/detail" component={BookingDetail} />
                  <ContentRoute
                    key={'create-branch'}
                    exact
                    path="/shop/:shopId/booking2-createbooking/"
                    component={Booking2CreateBookingSelectBranchPage}
                  />
                  <ContentRoute
                    key={'create-workingdays'}
                    path="/shop/:shopId/booking2-createbooking/:branchId/workingdays"
                    component={Booking2BookingWorkingDaysPage}
                  />
                  <ContentRoute
                    key={'create-condition'}
                    path="/shop/:shopId/booking2-createbooking/:branchId/condition"
                    component={Booking2BookingConditionPage}
                  />
                  <ContentRoute
                    key={'booking-create'}
                    path="/shop/:shopId/booking2-createbooking/:branchId/create"
                    component={Booking2CreateBookingPage}
                  />
                  <ContentRoute
                    key={'booking-detail'}
                    path="/shop/:shopId/booking2/:bookingId/detail"
                    component={Booking2DetailPage}
                  />
                  <ContentRoute exact path="/liff" component={LiffLogin} />
                  <ContentRoute exact path="/shareTarget" component={MGM_Detail} />

                  <Redirect from="/admin" to="/admin/login" />
                  <Redirect from="/shop/:seqId" to={`/shop/${seqId}/login?${loginPageQueryParams}`} />
                  <Redirect to="/error" />
                </Switch>
              </div>
            </div>

            {/* begin::Mobile footer */}
            {/* <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2"></div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 font-weight-bold text-hover-primary">
                  Privacy
                </Link>
                <Link to="/terms" className="text-dark-75 font-weight-bold text-hover-primary ml-4">
                  Legal
                </Link>
                <Link to="/terms" className="text-dark-75 font-weight-bold text-hover-primary ml-4">
                  Contact
                </Link>
              </div>
            </div> */}
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
